@import '../node_modules/antd/dist/antd.css';

.ant-btn-primary {
  @apply bg-gray-800;
  @apply border-gray-800;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  @apply bg-gray-600;
  @apply border-gray-600;
}

a {
  @apply text-gray-800;
  text-decoration: underline
}

a:active {
  @apply text-gray-900;
}

a:hover {
  @apply text-gray-600;
}
